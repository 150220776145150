<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="isLoading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-row>
                                    <b-col lg="6">
                                        <ValidationProvider name="Mill Name" vid="mill_name_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mill_name_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                                </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.mill_info_id"
                                                :options="millInfoList"
                                                id="mill_name_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col lg="6">
                                    <ValidationProvider name="Sub Zone Name" vid="sub_zone_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="sub_zone_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('bsri_config.sub_zone_name') }} <span class="text-danger">*</span>
                                            </template>
                                        <b-form-select
                                            plain
                                            v-model="formData.subzone_office_id"
                                            :options="subZoneList"
                                            id="sub_zone_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6">
                                    <ValidationProvider name="center Name" vid="center_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="center_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('bsri_config.center_name') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.center_id"
                                                :options="centerList"
                                                id="center_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                </b-row>
                                <b-card
                                    border-variant="success"
                                    :header="$t('bsri_config.unit_no')"
                                    header-bg-variant="success"
                                    header-text-variant="white">
                                    <b-overlay :show="unitload">
                                    <b-row v-for="(detail,index) in formData.unit_list_details" :key="index" class="text-black mb-3">
                                        <b-col xl="6" lg="6" sm="6">
                                        <ValidationProvider name="Sorting Order" vid="unit_no" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="unit_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('bsri_config.unit_no') + ' ' + $n(index + 1) }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                id="unit_no"
                                                v-on:blur="hello()"
                                                v-model="detail.unit_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback d-block" v-if="allErrors['unit_list_details.'+ index + '.unit_no']">
                                                {{ allErrors['unit_list_details.'+ index + '.unit_no'][0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="6">
                                        <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button" v-show="index == formData.unit_list_details.length-1"><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                        <button @click="remove(index)" class="btn btn-sm btn-danger" type="button" v-show="index || ( !index && formData.unit_list_details.length > 1)"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                        </b-col>
                                    </b-row>
                                    </b-overlay>
                                </b-card>
                                <b-row class="mt-2">
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <ValidationProvider name="Description (En)" vid="description_en" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="2"
                                                label-for="description_en"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.description_en')}}
                                            </template>
                                                <b-form-textarea
                                                id="description_en"
                                                v-model="formData.description"
                                                rows="1"
                                                max-rows="2"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <ValidationProvider name="Description (Bn)" vid="description_bn" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="2"
                                                label-for="description_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.description_bn')}}
                                            </template>
                                                <b-form-textarea
                                                id="description_bn"
                                                v-model="formData.description_bn"
                                                rows="1"
                                                max-rows="2"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Office Type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="area_type_id" vid="area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="area_type_id "
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.area_type_id"
                                                id="area_type_id"
                                                :options="getAreaTypeList"
                                                @change="getAreaTypeData(formData.area_type_id)"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.division_id"
                                                :options="divisionList"
                                                id="division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                                        <ValidationProvider name="Upazila" vid="upazila_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.upazilla_id"
                                                :options="upazilaList"
                                                id="upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="UnionItemShow">
                                        <ValidationProvider name="Union" vid="union_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="union_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.union_id"
                                                :options="unionList"
                                                id="union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                                        <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="city_corporation_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.city_corporation_id"
                                                :options="cityCorporationList"
                                                id="city_corporation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                                        <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="pauroshoba_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.pauroshoba_id"
                                                :options="pauroshobaList"
                                                id="pauroshoba_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShow">
                                        <ValidationProvider name="Ward" vid="ward_id" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="ward_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.ward') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.ward_id"
                                                :options="wardList"
                                                id="ward_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { unitlistStore, officeEntryStoreunit, officeEntryStorecentervalidate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.millInfoList = this.getMillInfoList()
    this.officeTypeList = this.getOfficeTypeList()
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      unitload: false,
      formData: {
        mill_info_id: '0',
        subzone_office_id: '0',
        center_id: '0',
        description: '',
        description_bn: '',
        unit_list_details: [
          {
            unit_no: '',
            office_id: '0'
          }
        ],
        org_id: 8,
        office_type_id: '0',
        area_type_id: '0',
        city_corporation_id: '0',
        pauroshoba_id: '0',
        ward_id: '0',
        country_id: 1,
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        office_name: '',
        office_name_bn: '',
        office_code: '1',
        parent_office_type_id: '0',
        parent_office_id: '0',
        created_by: 1,
        updated_by: 1,
        is_regional_office: 0
      },
      millInfoList: [],
      subZoneList: [],
      centerList: [],
      allErrors: {},
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
              office: {
                org_id: '0',
                office_type_id: '0',
                area_type_id: '0',
                city_corporation_id: '0',
                pauroshoba_id: '0',
                ward_id: '0',
                country_id: 1,
                division_id: '0',
                district_id: '0',
                upazilla_id: '0',
                union_id: '0',
                office_name: '',
                office_name_bn: '',
                office_code: '1',
                parent_office_type_id: '0',
                parent_office_id: '0',
                created_by: 1,
                updated_by: 1,
                is_regional_office: 0
            },
            officeTypeList: [],
            districtList: [],
            wardList: [],
      isLoading: false
    }
  },
  computed: {
    millTypeList: function () {
        const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
        return millTypeList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
     divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
         orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        grantTypeList: function () {
            return this.grant_types.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
  },
  watch: {
    'formData.mill_type_id': function (newVal) {
      this.millInfoList = this.getMillInfoList(newVal)
      this.subZoneList = []
      this.centerList = []
    },
    'formData.mill_info_id': function (newVal, oldVal) {
      this.subZoneList = this.subzoneOfficeListt(newVal)
    },
    'formData.subzone_office_id': function (newVal) {
      this.centerList = this.getCenterList(newVal)
    },
    'formData.org_id': function (newVal, oldVal) {
            this.officeTypeList = this.getOfficeTypeList(newVal)
    },
    'formData.division_id': function (newVal, oldVal) {
    this.districtList = this.getDistrictList(newVal)
    },
    'formData.district_id': function (newVal, oldVal) {
    this.cityCorporationList = this.getCityCorporationList(newVal)
    this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
    this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'formData.city_corporation_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'formData.pauroshoba_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'formData.union_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByUnion(newVal)
    },
    'formData.center_id': function (newVal, oldVal) {
    this.formData.parent_office_id = this.formData.center_id
    }
  },
  methods: {
              getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === 8)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === 8).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
             subzoneOfficeListt (newVal) {
            const dataObject = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1 && item.mill_info_id === newVal)
            return dataObject.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
                })
            },
              getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
       getFormData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
       return JSON.parse(JSON.stringify(tmpData))
       },
       async saveData () {
        this.isLoading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        await RestApi.postData(commonServiceBaseUrl, officeEntryStoreunit, this.formData).then(response => {
        const loadingState = { loading: false, listReload: false }
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (response.success) {
            let i = 0
              try {
                for (i = 0; i < response.id.length; i++) { this.formData.unit_list_details[i].office_id = response.id[i] }
                } catch (e) {
                }
            RestApi.postData(incentiveGrantServiceBaseUrl, unitlistStore, this.formData).then(response => {
            if (response.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })
            this.$bvModal.hide('modal-5')
           }
            })
           } else {
                this.isLoading = false
                this.unitLoad = false
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'The Unit has already been taken',
                color: '#D6E09B'
                })
         }
        this.isLoading = false
        })
    },
    hello () {
      this.addItemtwo()
    },
    async addItem () {
    this.unitload = true
    if (this.formData.center_id === '0') {
        this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please select a center name first',
        color: '#D6E09B'
        })
        this.unitload = false
        return
    }
      const obj = {
        unit_no: ''
      }
      const key1 = parseInt(this.formData.unit_list_details.length - 1)
      const item = this.formData.unit_list_details[key1]
      let isEmpty = false
      if (item.unit_no === '') {
        isEmpty = true
        this.unitload = false
      }
      if (isEmpty === false) {
          let result = null
          result = await RestApi.postData(commonServiceBaseUrl, officeEntryStorecentervalidate, { center: this.formData.center_id, unit: item.unit_no })
          if (result.success === true) {
           this.unitload = false
           this.formData.unit_list_details.push(obj)
          } else {
                item.unit_no = ''
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'this unit exist under this center',
                color: '#D6E09B'
            })
            this.unitload = false
          }
      }
    },
    async addItemtwo () {
        this.unitload = true
    if (this.formData.center_id === '0') {
        this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please select a center name first',
        color: '#D6E09B'
        })
        this.unitload = false
        return
    }
      const key1 = parseInt(this.formData.unit_list_details.length - 1)
      const item = this.formData.unit_list_details[key1]
      let isEmpty = false
      if (item.unit_no === '') {
        isEmpty = true
        this.unitload = false
      }
      if (isEmpty === false) {
          let result = null
          result = await RestApi.postData(commonServiceBaseUrl, officeEntryStorecentervalidate, { center: this.formData.center_id, unit: item.unit_no })
          if (result.success === true) {
              this.unitload = false
          } else {
                item.unit_no = ''
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'this unit exist under this center',
                color: '#D6E09B'
            })
            this.unitload = false
          }
      }
    },
    remove (key) {
        this.formData.unit_list_details.splice(key, 1)
    },
    getMillInfoList: function (millTypeId) {
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return millInfoList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  }
}
</script>
<style scoped>
[dir="ltr"][mode="light"] .card {
  border: 1px solid;
}
</style>
