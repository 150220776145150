
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_config.unit_no_search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Mill Name" vid="mill_name_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mill_name_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="search.mill_info_id"
                                :options="millInfoList"
                                id="mill_name_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Sub Zone Name" vid="sub_zone_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sub_zone_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('bsri_config.sub_zone_name') }}
                                </template>
                            <b-form-select
                                plain
                                v-model="search.subzone_office_id"
                                :options="subZoneList"
                                id="sub_zone_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="center Name" vid="center_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="center_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('bsri_config.center_name') }}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.center_id"
                                    :options="centerList"
                                    id="center_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.unit_no_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-5 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(unit_no)="data">
                                          {{ $n(data.item.unit_no,{ useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_view" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Edit :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Edit from './Edit'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { unitList, unitlistStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Edit
  },
  data () {
    return {
      search: {
        mill_info_id: '0',
        subzone_office_id: '0',
        center_id: '0'
      },
      millInfoList: [],
      subZoneList: [],
      centerList: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
    millTypeList: function () {
        const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
        return millTypeList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_config.unit_no') + ' ' + this.$t('globalTrans.entry') : this.$t('bsri_config.unit_no') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('bsri_config.mill_name'), class: 'text-center' },
          { label: this.$t('bsri_config.sub_zone_name'), class: 'text-center' },
          { label: this.$t('bsri_config.center_name'), class: 'text-center' },
          { label: this.$t('bsri_config.unit_no'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'mill_name_bn' },
          { key: 'sub_zone_name_bn' },
          { key: 'center_name_bn' },
          { key: 'unit_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'mill_name_en' },
          { key: 'sub_zone_name_en' },
          { key: 'center_name_en' },
          { key: 'unit_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    'search.mill_type_id': function (newVal) {
      this.millInfoList = this.getMillInfoList(newVal)
      this.subZoneList = []
      this.centerList = []
    },
    'search.mill_info_id': function (newVal) {
      this.subZoneList = this.getSubZoneList(newVal)
      this.centerList = []
    },
    'search.subzone_office_id': function (newVal) {
      this.centerList = this.getCenterList(newVal)
    }
  },
  created () {
    this.loadData()
    this.millInfoList = this.getMillInfoList()
    this.subZoneList = this.getSubZoneList()
    this.centerList = this.getCenterList()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, unitlistStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, unitList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
        const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item)
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item)
        const listData = data.map(item => {
            const millTypeObj = millTypeList.find(millType => millType.value === item.mill_type_id)
            const millTypeData = { mill_type_en: millTypeObj !== undefined ? millTypeObj.text_en : '', mill_type_bn: millTypeObj !== undefined ? millTypeObj.text_bn : '' }
            const millNameObj = millInfoList.find(millInfoItem => millInfoItem.value === item.mill_info_id)
            const millNameData = { mill_name_en: millNameObj !== undefined ? millNameObj.text_en : '', mill_name_bn: millNameObj !== undefined ? millNameObj.text_bn : '' }
            const subZoneObj = subZoneList.find(subZoneItem => subZoneItem.value === item.subzone_office_id)
            const subZoneData = { sub_zone_name_en: subZoneObj !== undefined ? subZoneObj.text_en : '', sub_zone_name_bn: subZoneObj !== undefined ? subZoneObj.text_bn : '' }
            const centerListObj = centerList.find(centerItem => centerItem.value === item.center_id)
            const centerNameData = { center_name_en: centerListObj !== undefined ? centerListObj.text_en : '', center_name_bn: centerListObj !== undefined ? centerListObj.text_bn : '' }
            return Object.assign({}, item, millTypeData, millNameData, subZoneData, centerNameData)
        })
        return listData
    },
    getMillInfoList: function (millTypeId) {
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return millInfoList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  }
}
</script>
<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
